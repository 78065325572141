<template>
  <div v-if='showSideNav'
    class='background-style text-white py-4 px-4 lg:py-16 lg:px-16'
    :style='`${backgroundStyle}`'>
    <div class='lg:sticky lg:top-32'>
      <h2 class='text-2xl capitalize font-semi pl-2'>APSR 2022</h2>
      <ul class='text-xs lg:text-lg mt-4 about-page flex flex-row lg:flex-col overflow-x-auto lg:overflow-x-hidden'>
        <li>
          <router-link :to='{ name: "Welcome" }'  class='py-2 pl-2 pr-2 uppercase opacity-90 flex flex-col-reverse lg:flex-row items-center justify-end lg:justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/favicon-32x32.png' class='standard-transition opacity-50'>
            <span>Welcome Message</span>
          </router-link>
        </li>
        <li>
          <router-link :to='{ name: "Overview" }' class='py-2 pl-2 pr-2 uppercase opacity-90 flex flex-col-reverse lg:flex-row items-center justify-end lg:justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/favicon-32x32.png' class='standard-transition opacity-50'>
            <span>Overview</span>
          </router-link>
        </li>
        <li>
          <router-link :to='{ name: "CommitteeCcc" }' class='py-2 pl-2 pr-2 uppercase opacity-90 flex flex-col-reverse lg:flex-row items-center justify-end lg:justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/favicon-32x32.png' class='standard-transition opacity-50'>
            <span>Committee CCC</span>
          </router-link>
        </li>
        <li>
          <router-link :to='{ name: "CommitteeLcc" }'  class='py-2 pl-2 pr-2 uppercase opacity-90 flex flex-col-reverse lg:flex-row items-center justify-end lg:justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/favicon-32x32.png' class='standard-transition opacity-50'>
          <span>Committee LCC</span>
          </router-link>
        </li>
        <li>
          <router-link :to='{ name: "CongressLogo" }'  class='py-2 pl-2 pr-2 uppercase opacity-90 flex flex-col-reverse lg:flex-row items-center justify-end lg:justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/favicon-32x32.png' class='standard-transition opacity-50'>
          <span>Congress Logo</span>
          </router-link>
        </li>
        <li>
          <router-link :to='{ name: "AboutUs" }'  class='py-2 pl-2 pr-2 uppercase opacity-90 flex flex-col-reverse lg:flex-row items-center justify-end lg:justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/favicon-32x32.png' class='standard-transition opacity-50'>
          <span>About APSR & KATRD</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Apsr2022AboutPageNavigation',
  data () {
    return {
      showSideNav: true,
    }
  },
  watch: {
    '$route.query.on_mobile_app': {
      handler: function (newVal) { 
        if (!newVal) {// show if the query param on_mobile_app does not exist
          this.showSideNav = true
        } else if (newVal !== 'true') {//  or show if string is not 'true'
          this.showSideNav = true
        } else {
          this.showSideNav = false
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    backgroundStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    }
  }
}
</script>

<style lang='scss' scoped>

.background-style {
  background: transparent; 
  width: 100%;
}

.about-page .router-link-active {
  @apply font-bold;
}

.about-page img {
  @apply w-px;
}

.about-page .router-link-active img,
.about-page a:hover img {
  @apply opacity-100 w-6;
}
  
@media (min-width: 1024px) {
  .background-style {
    width: 24rem;
    height: auto;
    min-height: calc(100vh - 20rem);
  }
}


</style>


