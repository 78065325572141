<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-20'>
    <apsr2022-about-page-navigation class='flex-shrink-0' />
    <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
      <welcome-title title='Welcome Message' />
      <welcome-message-text class='leading-6 text-sm mb-4' />
      <greeters 
        :greeters='greeters'
        size='small' />
    </div>
  </div>
</template>

<script>
import Apsr2022AboutPageNavigation from '@/components/apsr2022/Apsr2022AboutPageNavigation.vue'
import WelcomeTitle                from '@/components/apsr2022/WelcomeTitle.vue'
import Greeters                    from '@/components/welcome/Greeters.vue'
import WelcomeMessageText          from '@/components/welcome/WelcomeMessageText.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'WelcomeMessage',
  components: {
    Apsr2022AboutPageNavigation,
    Greeters,
    WelcomeMessageText,
    WelcomeTitle,
  },
  computed: {
    ...mapGetters('events', [
      'eventConfigPresidentName',
      'eventConfigPresidentPosition',
      'eventConfigPresidentSignatureImageUrl',
      'eventConfigGreeters',
    ]),
    greeters () {
      if (this.eventConfigGreeters.length) {
        return this.eventConfigGreeters
      } else {
        return [
          {
            greeterName:       this.eventConfigPresidentName,
            greeterTitle:      this.eventConfigPresidentPosition,
            signatureImageUrl: this.eventConfigPresidentSignatureImageUrl,
          }
        ]
      }
    },
  },
}
</script>
